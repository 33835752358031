<template>
  <div
    v-show="spinner.visible"
    id="spinner"
    class="justify-content-center align-items-center"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">読み込み中</span>
    </div>
  </div>
</template>

<script lang="ts">
import { useSpinnerStore } from '@/store/spinner';

export default defineNuxtComponent({
  setup() {
    return {
      spinner: useSpinnerStore(),
    };
  },
});
</script>

<style lang="scss" scoped>
#spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  div {
    height: 6rem;
    width: 6rem;
  }
}
</style>
