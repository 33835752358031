<template>
  <div
    ref="modal"
    class="modal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div v-if="dialog.title" class="modal-header">
          <h5 class="modal-title">{{ dialog.title }}</h5>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="modal-body" v-html="dialog.message" />
        <div class="modal-footer flex-row-reverse justify-content-start">
          <button
            v-for="(text, index) in dialog.buttonLabels"
            :key="index"
            type="button"
            class="btn"
            style="width: calc(6em + 12px)"
            :class="index == 0 ? 'btn-primary' : ' btn-light'"
            @click="dialog.hideDialog(index)"
          >
            {{ text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Modal } from 'bootstrap';

import { useDialogStore } from '@/store/dialog';

export default defineNuxtComponent({
  setup() {
    return {
      dialog: useDialogStore(),
    };
  },
  data() {
    return {
      modal: null as Nullable<Modal>,
    };
  },
  watch: {
    'dialog.visible'(newVal: boolean) {
      if (newVal) {
        this.modal!.show();
      } else {
        this.modal!.hide();
      }
    },
  },
  mounted() {
    this.modal = new this.$bootstrap.Modal(this.$refs.modal as Element);
  },
});
</script>

<style lang="scss" scoped></style>
